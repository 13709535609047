import React, { CSSProperties } from "react"

const RedirectNewLDB = () => {
  const newURL = "https://ldb.urw.com"

  const loginStyle: CSSProperties = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/login_background.jpg")`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "center",
    backgroundSize: `cover`,
  }

  const traductions = [
    "Le Loyalty Dashboard évolue et est maintenant disponible à l’adresse",
    "Loyalty Dashboard is evolving and is now available at",
    "Loyalty Dashboard está evolucionando y ahora está disponible en",
  ]

  const renderRedirectionSentence = (sentence: string) => (
    <div className="flex justify-center space-x-4">
      <div>{sentence}</div>
      <a className="font-bold" href={newURL}>
        {newURL}
      </a>
    </div>
  )

  return (
    <div className="h-screen font-sans bg-cover flex justify-center items-center" style={loginStyle}>
      <div
        style={{
          backgroundColor: "rgb(255 255 255 / 0.8)",
        }}
        className="space-y-2 z-20 h-fit w-fit p-12 text-xl "
      >
        {traductions.map((traduction) => renderRedirectionSentence(traduction))}
      </div>
    </div>
  )
}

export default RedirectNewLDB
