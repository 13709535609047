import React from "react"
import "./App.css"
// import NavBar from "./components/navbar/Navbar"
import "./styles/css/fontello.css"
// import { BrowserRouter as Router } from "react-router-dom"
// import SnackbarProvider from "react-simple-snackbar"
// import { useStore } from "effector-react"
// import { ContributorStore, setFullUser, unsetUser } from "./store/ContributorStore"
// import credentialsManager from "./utils/CredentialsManager"
import Modal from "react-modal"
// import { execute } from "./api/api"
// import CustomSwitch from "./components/CustomSwitch"
// import { FullContributorType } from "./api/ContributorObject"
// import LoaderComponent from "./components/Loader"
// import { LoadingStore } from "./store/LoadingStore"
import RedirectNewLDB from "./views/Redirect"
//import Maintenance from "./views/Maintenance"

Modal.setAppElement("#root")

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  //return <Maintenance />
  return <RedirectNewLDB />
  // let retrievingLoggedInUser
  // const storedUser = useStore(ContributorStore)
  // if (
  //   (credentialsManager.getDashboardAccessToken() || credentialsManager.getDashboardRefreshToken()) &&
  //   !storedUser?.permissions?.profile
  // ) {
  //   retrievingLoggedInUser = true
  //   const myProfileWS = execute<FullContributorType>("profile", "GET")
  //   myProfileWS
  //     .then((res: FullContributorType) => {
  //       setFullUser(res)
  //     })
  //     .catch(() => {
  //       // Logout to restart a clean session
  //       credentialsManager.eraseDashboardAccessToken()
  //       credentialsManager.eraseDashboardRefreshToken()
  //       unsetUser()
  //     })
  //     .finally(() => {
  //       retrievingLoggedInUser = false
  //     })
  // }
  // const isLoggedIn =
  //   (credentialsManager.getDashboardAccessToken() || credentialsManager.getDashboardRefreshToken()) &&
  //   storedUser.isActivated
  // const storedLoading = useStore(LoadingStore)
  // return !retrievingLoggedInUser ? (
  //   <div className="App h-full w-full flex flex-col">
  //     <SnackbarProvider>
  //       <Router>
  //         {isLoggedIn ? <NavBar /> : ""}
  //         <div className="flex-auto max-h-full">
  //           {storedLoading > 0 && <LoaderComponent />}
  //           <CustomSwitch isLoggedIn={!!isLoggedIn} />
  //         </div>
  //       </Router>
  //     </SnackbarProvider>
  //   </div>
  // ) : (
  //   <LoaderComponent />
  // )
}

export default App
